<template>
  <div class="footer">
    <div class="content-top">
      <div class="content-left">
        <KoreanTitle no-gradients korean="읖초테">
          UPSHOOT
        </KoreanTitle>
      </div>
      <div class="content-right" />
    </div>

    <div class="content-bottom">
      <h6>
        Made with love and kpop by <a class="link" href="https://infographart.com/" target="_blank">www.infographart.com</a> <span v-if="!isMobile()" />
        –
        <a class="link" href="/mentions-legales">Mentions Légales</a>
        –
        v{{ version }}
        <span v-if="gitSha"> • <v-icon class=" mr-1" small>mdi-source-branch</v-icon>{{ gitSha }}</span>
      </h6>
    </div>
  </div>
</template>

<script>
import packageJson from "../../package.json";

import KoreanTitle from "@/components/KoreanTitle";

export default {
  name: "ComponentAppFooter",
  components: {
    KoreanTitle,
  },
  data() {
    return {
      version: packageJson.version,
    };
  },
  computed: {
    gitSha() {
      if (process.env.VUE_APP_GIT_SHA) return process.env.VUE_APP_GIT_SHA.slice(0, 7); else return false;
    },
  },
  methods: {
    isMobile() {
      if (window?.innerWidth) return window.innerWidth < 1040;
      else return false;
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: #d19cf5;
$secondary: #94dde6;
$gradient: linear-gradient(45deg, $primary, $secondary);

@media screen and (min-width: 1040px) {
  .footer {
    margin: 128px auto 0 auto;
    padding: 64px 128px 16px 128px;

    .content-bottom {
      flex-direction: row;
    }

    h1 {
      color: red;
    }
  }
}
@media screen and (max-width: 1040px) {
  .footer {
    margin: 140px auto 0 auto;
    padding: 0px 64px 32px 64px;

    .content-bottom {
      flex-direction: column;
      align-items: center;
    }

    h1 {
      color: red;
    }
  }
}
.footer {
  display: flex;
  width: 100%;
  max-width: 1664px;
  flex-direction: column;
  align-items: center;
  border-radius: 32px 32px var(--filterCard, 0px) var(--filterCard, 0px);

  background: linear-gradient(
    85deg,
    rgba(209, 156, 245, 0.6) 0%,
    rgba(148, 221, 230, 0.6) 100%
  );

  .content-top {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;

    .content-left {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 16px;
    }

    .content-right {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 64px;
      width: 256px;
      gap: 32px;
    }
  }

  .content-bottom {
    display: flex;
    padding-top: 128px;
    justify-content: center;
    gap: 6px;
    order: 2;
  }
}
</style>
