import { arrayUnion, arrayRemove, increment } from "firebase/firestore";
import { addDocument, deleteDocument, getDocument, getDocuments, queryDocument, setDocument, updateDocument } from "./firestore.service";
import { getUserInfo } from "./auth.service";

const projectsCollection = "projects";

export async function createProject(
  owner = String(),
  name = String(),
  song = {
    title: String(),
    author: String(),
  },
  tags = [String()],
  crewName = String(),
) {
  try {
    const project = {
      crewName,
      name,
      song,
      tags,

      ownerId: owner,
      status: Number(),
      joinCode: String(),
      members: [],

      attachments: {},

      counters: {
        scenes: 0,
        takes: 0,
      },

      creationDate: new Date(),
      modificationDate: new Date(),
    };

    if (!project.name && !project.author) throw new Error("🛑 No project name xor author was provided");

    const newProject = await addDocument(projectsCollection, project);

    updateProjectCode(newProject.id);

    setDocument(`${projectsCollection}/${newProject.id}/members/${owner}`, {
      joinDate: new Date(),
      role: 0,
    });

    return newProject.id;
  } catch (e) {
    console.error("❌ Error creating project : ", e);

    throw new Error(e);
  }
}

export function updateProject(
  projectId = String(),
  projectObject = Object(),
) {
  try {
    const song = {
      author: projectObject.song.author.trim(),
      title: projectObject.song.title.trim(),
    };

    const updatedProject = {
      crewName: projectObject.crewName.trim(),
      name: projectObject.name.trim(),
      song,
      tags: projectObject.tags,
      attachments: projectObject.attachments,
      description: projectObject.description.trim(),

      modificationDate: new Date(),
    };

    if (projectObject.attachments.referenceVideo) {
      const regex = /(?<=watch\?v=|\/videos\/|embed\/|youtu.be\/|\/v\/|watch\?v%3D|%2Fvideos%2F|embed%2F|youtu.be%2F|%2Fv%2F)[^#&?\n]*/gm;

      projectObject.attachments.referenceVideoId = projectObject.attachments.referenceVideo.match(regex)[0];
    }

    updateDocument(`${projectsCollection}/${projectId}`, updatedProject);

    return {
      error: false,
      project: updatedProject,
      message: "",
    };
  } catch (e) {
    const output = {
      error: true,
      project: {},
      message: "❌ Error updating project " + e,
    };

    console.error(output);

    return output;
  }
}

export function updateProjectOwner(projectId, newOwnerId) {
  try {
    updateDocument(`${projectsCollection}/${projectId}`, {
      owner: newOwnerId,
      modificationDate: new Date(),
    });

    return true;
  } catch (e) {
    console.error("❌ Error updating project owner : ", e);
    return false;
  }
}

export function updateProjectStatus(projectId, status) {
  try {
    updateDocument(`${projectsCollection}/${projectId}`, {
      status,
      modificationDate: new Date(),
    });

    return true;
  } catch (e) {
    console.error("❌ Error updating project status : ", e);
    return false;
  }
}

export function getProjectStep(status) {
  const steps = [
    "Create",
    "Shoot",
    "Derush",
    "Celebrate",
  ];

  return steps[status];
}

export async function updateProjectCode(projectId) {
  function generatePinCode() {
    const digits = Array.from({ length: 10 }, (_, i) => String(i));

    digits.sort(() => Math.random() - 0.5);

    return digits.slice(0, 6).join("");
  }

  const newPinCode = generatePinCode();

  try {
    // Check if project with same pin exists
    const docs = await queryDocument("projects", [
      ["pinCode", "==", newPinCode],
    ]);

    if (docs.length > 0) return false;

    updateDocument(`${projectsCollection}/${projectId}`, {
      pinCode: newPinCode,
      modificationDate: new Date(),
    });

    return newPinCode;
  } catch (e) {
    console.error("❌ Error updating project pinCode : ", e);
    return false;
  }
}

export async function getUserProjects(userId, onlyOwned = false) {
  function findWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1)
      if (array[i][attr] === value)
        return i;

    return -1;
  }

  const ownedProjects = await queryDocument("projects", [
    ["ownerId", "==", userId],
  ]);

  if (onlyOwned) return ownedProjects;
  else {
    const userData = await getDocument(`users/${userId}`);

    const joinedProjets = [];
    if (userData.projects?.length) {
      const articlesPromise = new Promise((resolve) => {
        userData.projects.forEach(async (projectId, index) => {
          const t = await getDocument(`${projectsCollection}/${projectId}`);

          if (findWithAttr(ownedProjects, "id", projectId) === -1) joinedProjets.push(t);

          if (userData.projects.length === index + 1) resolve(joinedProjets);
        });
      });

      return Promise.resolve(articlesPromise).then(() => {
        return ownedProjects.concat(joinedProjets);
      });
    } else return ownedProjects;
  }
}

export function getProject(projectid) {
  return getDocument(`${projectsCollection}/${projectid}`);
}

export async function getProjectWithPinCode(pinCode) {
  try {
    const docs = await queryDocument("projects", [
      ["pinCode", "==", pinCode],
    ]);

    let output = {};

    if (docs.length === 0) output = {
      error: true,
      project: {},
      message: `🔍 No project found with this pinCode`,
    };
    else output = {
      error: false,
      project: {
        ...docs[0],
        id: docs[0].id,
      },
      message: "",
    };

    console.debug(output);

    return output;
  } catch (e) {
    const output = {
      error: true,
      project: {},
      message: "❌ Error fetching project with pinCode" + e,
    };

    console.error(output);

    return output;
  }
}

export async function deleteProject(projectId) {
  try {
    const members = (await getProjectMembers(projectId)).members;

    members.forEach((member) => {
      userLeaveProject(projectId, member.id);
    });

    const scenes = await getScenesInProject(projectId);
    scenes.documents.forEach(async (scene) => { await removeSceneInProject(projectId, 0, scene.id); });

    await deleteDocument(`${projectsCollection}/${projectId}`);

    return {
      projectDeleted: true,
    };
  } catch (error) {
    const output = {
      error,
      projectDeleted: false,
      message: "❌ Error deleting the project " + error,
    };

    console.error(output);

    return output;
  }
}

/**
 * USER
 */
export async function isPartOfProject(projectId, userId) {
  if (userId === 0) return true;

  const isOwnerOfProject = (await getDocument(`${projectsCollection}/${projectId}`)).ownerId === userId;

  if (isOwnerOfProject) return isOwnerOfProject;
  else return !!(await getDocument(`${projectsCollection}/${projectId}/members/${userId}`)).joinDate;
}

export async function joinProjectWithPinCode(userId, pinCode) {
  try {
    const projectRes = await getProjectWithPinCode(pinCode);

    if (projectRes.error || !projectRes.project?.id) {
      const output = {
        error: true,
        project: projectRes.project,
        message: projectRes.message,
      };

      console.error(output);

      return output;
    }

    const alreadyMember = await isPartOfProject(projectRes.project.id, userId);
    if (!alreadyMember) userJoinProject(projectRes.project.id, userId);

    const output = {
      error: false,
      project: projectRes.project.id,
      message: "",
    };

    console.debug(output);

    return output;
  } catch (e) {
    const output = {
      error: true,
      project: {},
      message: "❌ Error fetching project and joining project : " + e,
    };

    console.error(output);

    return output;
  }
}

export async function userJoinProject(projectId, userId) {
  try {
    // Update project
    setDocument(`${projectsCollection}/${projectId}/members/${userId}`, {
      joinDate: new Date(),
      role: 0,
    });

    // Update user
    updateDocument(`users/${userId}`, {
      projects: arrayUnion(projectId),
    });

    return projectId;
  } catch (e) {
    const output = {
      error: true,
      project: {},
      message: "❌ Error joining project" + e,
    };

    console.error(output);

    return output;
  }
}
export async function userLeaveProject(projectId, userId) {
  try {
    // Update project
    const deleteProcess = await deleteDocument(`${projectsCollection}/${projectId}/members/${userId}`);

    // Update user
    const updateProcess = await updateDocument(`users/${userId}`, {
      projects: arrayRemove(projectId),
    });

    if (deleteProcess && updateProcess) return projectId;
    else false;
  } catch (e) {
    console.error("❌ Error leaving project : ", e);
    return false;
  }
}

export async function getProjectMembers(projectId) {
  try {
    const docs = await getDocuments(`${projectsCollection}/${projectId}/members`);

    const projectMembers = [];

    const membersPromise = new Promise((resolve) => {
      if (docs.size === 0) resolve(projectMembers);
      else docs.documents.forEach(async (member) => {
        const t = await getUserInProject(projectId, member.id);

        projectMembers.push({
          ...member,
          ...t.userData,
        });

        if (docs.size === projectMembers.length) resolve(projectMembers);
      });
    });

    return Promise.resolve(membersPromise).then(() => {
      return {
        members: projectMembers,
      };
    });
  } catch (error) {
    const output = {
      error,
      members: [],
      message: "❌ Unable to get project members",
    };

    console.error(output);

    return output;
  }
}

export async function getUserInProject(projectId, userId) {
  try {
    const alreadyMember = await isPartOfProject(projectId, userId);

    if (alreadyMember) {
      const userData = await getUserInfo(userId);
      return {
        isPartOfProject: alreadyMember,
        userData,
      };
    } else return {
      isPartOfProject: alreadyMember,
      userData: {},
    };
  } catch (e) {
    console.error("❌ Error retreiving user from project : ", e);
    return false;
  }
}

export async function setUserRoleInProject(projectId, userId, role) {
  try {
    const alreadyMember = await isPartOfProject(projectId, userId);

    if (alreadyMember)
      updateDocument(`${projectsCollection}/${projectId}/members/${userId}`, {
        memberRole: role,
      });
    else throw new Error("⚠️ User is not part of the project");
  } catch (e) {
    console.error("❌ Error adding role to user in project : ", e);
    return false;
  }
}

export async function updateUserInProject(projectId, userId, memberNickname = null, memberRepresents = null) {
  try {
    const alreadyMember = await isPartOfProject(projectId, userId);

    if (alreadyMember)
      updateDocument(`${projectsCollection}/${projectId}/members/${userId}`, {
        nickname: memberNickname,
        represents: memberRepresents,
      });
    else throw new Error("⚠️ User is not part of the project");
  } catch (e) {
    console.error("❌ Error updating user in project : ", e);
    return false;
  }
}

/**
 * SCENES / TAKES
 */
export async function createSceneInProject(projectId, userId, scenesNo, description, timecodes = [], dancers = []) {
  try {
    const alreadyMember = await isPartOfProject(projectId, userId);

    if (alreadyMember) {
      if (timecodes.length > 0) timecodes = [
        timecodes[0].replace(".", ":"),
        timecodes[1].replace(".", ":"),
      ];

      const sceneDocument = {
        key: scenesNo,
        description,
        timecodes,
        dancers,

        isTaken: false,
        isSafeTaken: false,

        counters: {
          takes: 0,
        },

        creationDate: new Date(),
        createdBy: userId,
      };

      const sceneDoc = await addDocument(`${projectsCollection}/${projectId}/scenes`, sceneDocument);

      updateDocument(`${projectsCollection}/${projectId}`, {
        "counters.scenes": increment(1),
      });

      return sceneDoc;
    } else throw new Error("⚠️ User is not part of the project");
  } catch (e) {
    const output = {
      error: true,
      scene: {},
      message: "❌ Error creating scene in project : " + e,
    };

    console.error(output);

    return output;
  }
}

export async function setSceneInProject(projectId, userId, sceneId, description, timecodes = [], dancers = []) {
  try {
    const alreadyMember = await isPartOfProject(projectId, userId);

    if (alreadyMember) {
      if (timecodes.length > 0) timecodes = [
        timecodes[0].replace(".", ":"),
        timecodes[1].replace(".", ":"),
      ];

      const sceneDoc = await updateDocument(`${projectsCollection}/${projectId}/scenes/${sceneId}`, {
        description,
        timecodes,
        dancers,
      });

      return sceneDoc;
    } else throw new Error("⚠️ User is not part of the project");
  } catch (e) {
    console.error("❌ Error updating scene in project : ", e);
    return false;
  }
}

export async function removeSceneInProject(projectId, userId, sceneId) {
  if (!sceneId) throw new Error("❌ SceneId was not provided");

  try {
    const alreadyMember = await isPartOfProject(projectId, userId);

    if (alreadyMember) {
      // Delete takes
      const takes = await getTakesFromSceneInProject(projectId, sceneId);
      takes.documents.forEach(async (take) => { await deleteDocument(`${projectsCollection}/${projectId}/scenes/${sceneId}/takes/${take.id}`); });

      await updateDocument(`${projectsCollection}/${projectId}`, {
        "counters.takes": increment(-takes.size),
      });

      await deleteDocument(`${projectsCollection}/${projectId}/scenes/${sceneId}`);

      await updateDocument(`${projectsCollection}/${projectId}`, {
        "counters.scenes": increment(-1),
      });
    } else throw new Error("⚠️ User is not part of the project");
  } catch (e) {
    console.error("❌ Error deleting scene in project : ", e);
    return false;
  }
}

export async function getScenesInProject(projectId) {
  try {
    return await getDocuments(`${projectsCollection}/${projectId}/scenes`);
  } catch (e) {
    console.error("❌ Error getting scenes in project : ", e);
    return false;
  }
}

export async function createTakeInProject(
  projectId,
  userId,
  sceneId,
  type,
  comment,
  camera,
) {
  try {
    const alreadyMember = await isPartOfProject(projectId, userId);

    if (alreadyMember) {
      const takeDocument = {
        type,
        comment,
        camera,

        creationDate: new Date(),
        createdBy: userId,
      };

      const takeDoc = await addDocument(`${projectsCollection}/${projectId}/scenes/${sceneId}/takes`, takeDocument);

      updateDocument(`${projectsCollection}/${projectId}`, {
        "counters.takes": increment(1),
      });

      updateDocument(`${projectsCollection}/${projectId}/scenes/${sceneId}`, {
        "counters.takes": increment(1),
      });

      return {
        id: takeDoc.id,
        ...takeDocument,
      };
    } else throw new Error("⚠️ User is not part of the project");
  } catch (e) {
    console.error("❌ Error creating scene in project : " + e);

    throw new Error(e);
  }
}

export async function setTakeInProject(projectId, userId, sceneId, action) {
  function updateTakeStatus(object) {
    updateDocument(`${projectsCollection}/${projectId}/scenes/${sceneId}`, object);
  }

  try {
    const alreadyMember = await isPartOfProject(projectId, userId);

    if (alreadyMember) {
      switch (action.toLowerCase()) {
        case "taken":
          updateTakeStatus({
            isTaken: true,
          });
          break;
        case "safetaken":
          updateTakeStatus({
            isTaken: true,
            isSafeTaken: true,
          });
          break;
        default:
          throw new Error(`⚠️ Action ${action} is not supported.`);
      };
    } else throw new Error("⚠️ User is not part of the project");
  } catch (e) {
    console.error("❌ Error creating scene in project : " + e);

    throw new Error(e);
  }
}

export async function removeTakeFromSceneInProject(projectId, userId, sceneId, takeId) {
  if (!sceneId) throw new Error("❌ SceneId was not provided");

  try {
    const alreadyMember = await isPartOfProject(projectId, userId);

    if (alreadyMember) {
      await deleteDocument(`${projectsCollection}/${projectId}/scenes/${sceneId}/takes/${takeId}`);

      await updateDocument(`${projectsCollection}/${projectId}`, {
        "counters.takes": increment(-1),

      });

      await updateDocument(`${projectsCollection}/${projectId}/scenes/${sceneId}`, {
        "counters.takes": increment(-1),
      });
    } else throw new Error("⚠️ User is not part of the project");
  } catch (e) {
    console.error("❌ Error deleting take from scenes in project : " + e);

    throw new Error(e);
  }
}

export async function getTakesFromSceneInProject(projectId, sceneId) {
  try {
    return await getDocuments(`${projectsCollection}/${projectId}/scenes/${sceneId}/takes`);
  } catch (e) {
    console.error("❌ Error getting takes from scenes in project : " + e);

    throw new Error(e);
  }
}
