import { createRouter, createWebHistory } from "vue-router";

import { auth } from "@/services/auth.service";

import * as Sentry from "@sentry/vue";

import {
  onAuthStateChanged,
} from "firebase/auth";
import { push } from "notivue";
import { isPartOfProject } from "@/services/project.service";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: () => import("@/views/HomeView.vue"),
    },
    {
      path: "/access",
      component: () => import("@/views/Access.vue"),
    },
    {
      path: "/profile",
      component: () => import("@/views/Profile.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/projects",
      component: () => import("@/views/Projects.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/project/:projectId",
      component: () => import("@/views/Project.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        privateProject: true,
      },
    },
    {
      path: "/project/:projectId/create",
      component: () => import("@/views/project/ProjectStepCreate.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        privateProject: true,
      },
    },
    {
      path: "/project/:projectId/shoot",
      component: () => import("@/views/project/ProjectStepShoot.vue"),
      props: true,
      meta: {
        requiresAuth: true,
        privateProject: true,
      },
    },
    {
      path: "/project/join/:projectPincode",
      component: () => import("@/views/project/JoinProjectView.vue"),
      props: true,
    },
  ],
});

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        unsubscribe();

        if (user) Sentry.setUser({
          id: user.uid,
          email: user.email,
          username: user.displayName,
        });

        resolve(user);
      },
      reject,
    );
  });
}

router.beforeEach(async (to, from) => {
  console.log(`🛣️ Route movement from ${from.path} to ${to.path}`);

  const currUser = await getCurrentUser();

  // Try to access to logged only ressource
  if (to.meta.requiresAuth && !currUser) return "/access";
  // Try to access to unauthorized project
  else if (
    to.meta.privateProject &&
    to.params.projectId &&
    !(await isPartOfProject(to.params.projectId, currUser.uid))
  ) {
    push.error("Vous n'avez pas accès à ce projet.");

    Sentry.captureMessage(`User ${currUser.uid} tried to access ${to.path} but wasn't allowed.`, "warning");

    return "/projects";
  }

  return true;
});

export default router;
