<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.96631 12.4116H21.9663" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M3.96631 6.41162H21.9663" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M3.96631 18.4116H21.9663" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: "MenuIcon",
};
</script>
